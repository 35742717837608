import * as React from 'react'

import Page from '../../../components/Page'
import Container from '../../../components/Container'
import IndexLayout from '../../../layouts'

const PaymentSuccessPage = () => (
  <IndexLayout>
    <Page>
      <Container>
        <h2>Vielen Dank für die Bezahlung.</h2>
      </Container>
    </Page>
  </IndexLayout>
)

export default PaymentSuccessPage
